import React from "react"
import styled from "styled-components"
import { SocialIcon } from "react-social-icons"
import { rhythm } from "../utils/typography"

const Layout = ({ children }) => (
  <Wrapper>
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <main>{children}</main>
    </div>
    <Footer>
      © {new Date().getFullYear()} Petit Yogi{" "}
      <SocialIcon
        url="https://www.facebook.com/petityoginl/"
        style={{ height: 25, width: 25, marginLeft: 5 }}
      />
      <SocialIcon
        url="https://www.instagram.com/petityoginl/"
        style={{ height: 25, width: 25, marginLeft: 5 }}
      />
    </Footer>
  </Wrapper>
)

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
  display: flex;
  justify-content: center;
  margin: 0 -5px;
`

export default Layout
